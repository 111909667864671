<template>
  <div class="px-6 md:px-20">
    <h3 class="mt-12 text-3xl font-medium text-gray-600">Get Started</h3>

    <section class="mt-8 ml-6 text-gray-800">
      <h4 class="-ml-6 text-xl font-medium text-gray-500">
        1. Create a zone file
      </h4>
      <p class="mt-2">
        The easiest way is to use the
        <router-link :to="{ name: 'CreateZone' }" class="underline"
          >generator</router-link
        >.<br />
        Simply add any records you want (and optionally HTTPS/Mail-specific
        records with the wizard).<br />
        Once done, click the Download button and save the zone file (with the
        .zone file extension).
      </p>
    </section>

    <section class="mt-8 ml-6 text-gray-800">
      <h4 class="-ml-6 text-xl font-medium text-gray-500">
        2. Sign the zone
      </h4>
      <p class="mt-2">
        This script generates keys and signs the zone file that you just
        downloaded. In the terminal, run:
      </p>
      <pre class="mt-2 p-2 rounded bg-gray-100 select-all overflow-x-auto">
bash &lt;(wget -qO- {{ origin }}/signzone.sh)</pre
      >
      <p class="mt-6 inline-block">
        Alternatively, run these commands manually:
      </p>
      <input
        type="text"
        class="ml-2 px-2 py-1 rounded border"
        v-model="domain"
        placeholder="Enter your domain name"
      />
      <pre class="mt-2 p-2 rounded bg-gray-100 select-all overflow-x-auto">{{
        commands
      }}</pre>
    </section>

    <section class="mt-8 ml-6 text-gray-800">
      <h4 class="-ml-6 text-xl font-medium text-gray-500">
        3. Update blockchain DNS
      </h4>
      <p class="mt-2">
        The script above prints a DS record. This (and another record) needs to
        be added on the Blockchain.<br />
        This change can take up to 6 hours to be confirmed.
      </p>
      <Tabs class="mt-4" :titles="{ bob: 'Bob Wallet', nb: 'Namebase' }">
        <template v-slot:bob>
          In Bob, go the domain's page either from Search or from Domain
          Manager.<br />
          Under Records, click on the delete icon to remove all rows with type
          NS.<br />
          Then add an NS record with value "ns1.rithvikvibhu.com." (note the
          trailing dot.)<br />
          Similarly, add the DS record with value from the script (the numbers
          from after "DS").
          <img src="https://i.imgur.com/WQptPFs.png" alt="" />
          Make sure that both records are added before clicking Submit!
        </template>
        <template v-slot:nb>
          Open the domain's page on Namebase (or
          <a
            :href="`https://www.namebase.io/domain-manager/${domain}`"
            target="_blank"
            rel="noopener noreferrer"
            class="underline"
            >click here</a
          >).<br />
          Scroll down to Blockchain DNS (not Namebase DNS).<br />
          Click on the delete icon to remove all rows with type NS.<br />
          Then add an NS record with value "ns1.rithvikvibhu.com." (note the
          trailing dot.)<br />
          Similarly, add the DS record with value from the script (the numbers
          from after "DS"). Leave the "Name" field blank.
          <img class="h-80" src="https://i.imgur.com/ZgzQKVt.png" alt="" />
          Click on Save.
        </template>
      </Tabs>
    </section>

    <section class="mt-8 ml-6 text-gray-800">
      <h4 class="-ml-6 text-xl font-medium text-gray-500">
        4. Upload the zone file
      </h4>
      <p class="mt-2">
        Go back to the home page and select the signed zone file that the script
        generated.<br />
        If there are no errors and all checks are ticked, then click Update to
        go live.<br />
        If the error that there was a problem verifying the zone, the record
        update may not be propagated. It can take up to 6 hours, so try again
        after a while.
      </p>
    </section>

    <section class="mt-8 ml-6 text-gray-800">
      <h4 class="-ml-6 text-xl font-medium text-gray-500">
        5. Done!
      </h4>
      <p class="mt-2">
        The records will be served immediately.<br />
        If there are no errors and all checks are ticked, then click Update to
        go live.<br />
        To test it, either visit the website or query DNS with dig:
      </p>
      <pre class="mt-2 p-2 rounded bg-gray-100 select-all">
dig {{ domain || 'DOMAINHERE' }} +dnssec</pre
      >
      <p class="mt-2">
        You can also see that DNSSEC works properly using
        <a
          href="http://dnssec.rithvik"
          target="_blank"
          rel="noopener noreferrer"
          class="underline"
          >http://dnssec.rithvik</a
        >
      </p>
    </section>

    <br /><br />
  </div>
</template>

<script>
import Tabs from '@/components/Tabs.vue'

export default {
  name: 'Home',
  components: {
    Tabs,
  },
  data() {
    return {
      domain: '',
      origin: '',
    }
  },
  methods: {
    // async onFileSelect(file) {
    //   console.log(file)
    //   this.$emit('zone-select', await file.text())
    // },
  },
  created() {
    this.origin = window.window.location.origin
  },
  computed: {
    commands() {
      const domain = this.domain || 'DOMAINNAME'
      return `dnssec-keygen -a NSEC3RSASHA1 -b 2048 -n ZONE ${domain}
dnssec-keygen -f KSK -a NSEC3RSASHA1 -b 4096 -n ZONE ${domain}
for key in \`ls K${domain}*.key\`; do echo "\\$INCLUDE $key">> ${domain}.zone; done
dnssec-signzone -A -3 $(head -c 1000 /dev/random | sha1sum | cut -b 1-16) -N INCREMENT -o ${domain} -t ${domain}.zone
cat dsset-${domain}.`
    },
  },
}
</script>
