<template>
  <div>
    <!-- Tab names -->
    <div>
      <ul
        class="py-2 inline-block divide-x rounded rounded-b-none border border-b-0 bg-gray-50"
      >
        <li
          v-for="tab in tabs"
          :key="tab"
          @click="activeTab = tab"
          class="px-4 inline cursor-pointer"
          :class="{ 'font-bold': tab === activeTab }"
        >
          {{ titles?.[tab] ?? tab }}
        </li>
      </ul>
    </div>

    <!-- Tab content -->
    <div class="rounded rounded-tl-none border-2">
      <div v-for="(tab, idx) in tabs" :key="idx">
        <div v-if="tab === activeTab" class="p-4">
          <slot :name="tab"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    titles: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tabs: [],
      activeTab: '',
    }
  },
  created() {
    for (let slot in this.$slots) {
      this.tabs.push(slot)
    }
    this.activeTab = this.tabs[0]
  },
  methods: {
    selectTab(newTab) {
      this.activeTab = newTab
    },
  },
}
</script>

<style></style>
